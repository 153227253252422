import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { ReactComponent as Logo } from '../../assets/ui_big_logo.svg';
import css from './LandingPage.module.css';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const LogoSection = props => {
  const { sectionId } = props;

  return (
    <div id={sectionId} className={css.headerLogoContainer}>
      <Logo className={css.headerLogo} color="black" />
    </div>
  );
};

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;
  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  const logoSection = {
    sectionId: 'mag-logo',
    sectionType: 'logo',
  };

  const customSections = pageData ? [logoSection, ...pageData.sections] : pageData?.sections;

  return (
    <PageBuilder
      pageAssetsData={{
        sections: customSections,
      }}
      options={{
        sectionComponents: {
          logo: { component: LogoSection },
        },
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
